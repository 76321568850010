import React from 'react'
import { Button } from "./Button"
import styled from 'styled-components'
import breakpoint from '../utils/breakpoints'

const ContinueReading = styled(Button)`
  ${breakpoint.lessThan('xsmall')`
    div {
      width: 100%;
    }
  `}
`

export default ContinueReading;
