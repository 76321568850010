import React from 'react'
import styled from 'styled-components'
import { Button } from './Button'
import config from '../utils/siteConfig'
import { ArrowRight } from 'styled-icons/fa-solid/ArrowRight'
import { ArrowLeft } from 'styled-icons/fa-solid/ArrowLeft'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
`

const PreviousLink = styled(Button)`
  align-self: flex-start;
  margin-right: auto;
  order: 1;
`

const NextLink = styled(Button)`
  align-self: flex-end;
  margin-left: auto;
  order: 2;
`

const RightArrowIcon = styled(ArrowRight)`
  width:1em;
`
const LeftArrowIcon = styled(ArrowLeft)`
  width:1em;
`

const PostLinks = props => {
  return (
    <Wrapper>
      { props.index !== 1 &&
        <PreviousLink to={props.previousUrl} aria-label="Previous Post"><LeftArrowIcon /> Prev</PreviousLink>
      }
      { props.index !== props.pageCount &&
        <NextLink to={props.nextUrl} aria-label="Next Post">Next <RightArrowIcon /></NextLink>
      }
    </Wrapper>
  )
}

export default PostLinks
