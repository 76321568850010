import React from 'react'
import Link from './Link'
import Article from './Article'
import Hero from './Hero'
import Footer from './PostFooter'
import Actions from './PostActions'
import ContinueReading from './PostContinueReading'
import Tag from './Tag'
import TagList from './TagList'
import Stats from './PostStats'
import Excerpt from './PostExcerpt'

const PostListItem = props => {
  return (
    <div key={props.id}>
      <Article>
        <Link to={`/${props.slug}/`}>
        <Hero
          title={props.title}
          image={props.image}
          author={props.author}
          subTitle={props.subTitle}
          date={props.date}
          avatar={props.avatar}
        />
        </Link>
        <Link to={`/${props.slug}/`} aria-label={`Read ${props.title}`}>
          <Excerpt
            dangerouslySetInnerHTML={{
              __html: props.excerpt.childMarkdownRemark.excerpt,
            }}
          />
        </Link>
        <Footer>
          <Actions>
            <li><ContinueReading large to={`/${props.slug}/`} aria-label={`Read ${props.title}`}>Continue Reading</ContinueReading></li>
          </Actions>
          <Stats>
            <TagList>
            {props.tags.map(tag => (
              <Tag key={tag.id} tag={tag}></Tag>
            ))}
            </TagList>
          </Stats>
        </Footer>
      </Article>
    </div>
  )
}

export default PostListItem
