import styled from 'styled-components';
import breakpoint from '../utils/breakpoints'

const Footer = styled.footer`
  display: flex;
  align-items: center;

  ${breakpoint.lessThan('xsmall')`
    align-items: stretch;
    flex-direction: column-reverse;
  `}
`

export default Footer;
