import React from 'react'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import PostListItem from '../components/PostListItem'
import Wrapper from '../components/Wrapper'
import { Button } from '../components/Button'
import PaginationLinks from '../components/PaginationLinks'
import SEO from '../components/SEO'

const Index = ({ data, pageContext }) => {
  const { group, index, pageCount } = pageContext;
  const previousUrl = index - 1 == 1 ? "/" : "/" + (index - 1).toString() + "/" ;
  const nextUrl = "/" + (index + 1).toString() + "/" ;

  return (
    <Layout>
      <SEO />
        {group.map(({ node: post }) => (
          <PostListItem
            key={post.id}
            id={post.id}
            slug={post.slug}
            image={post.heroImage}
            title={post.title}
            subTitle={post.subTitle}
            date={post.publishDate}
            dateTime={post.publishDateISO}
            tags={post.tags}
            excerpt={post.body}
            author={post.author.authorName}
          />
        ))}
        <PaginationLinks index={index} pageCount={pageCount} previousUrl={previousUrl} nextUrl={nextUrl} />
    </Layout>
  )
}

export default Index
